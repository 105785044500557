import React, { Component } from 'react';
import Dashboard from '../MainContent/Dashboard/Dashboard';
import Login from '../MainContent/Login';
import Register from '../MainContent/Register';
import ForgetPassword from '../MainContent/ForgetPassword';
import Drivers from '../MainContent/Drivers';
import AddDriver from '../MainContent/Drivers/addDriver';
import Customers from '../MainContent/Customers';
import AddCustomer from '../MainContent/Customers/addCustomer';
import Merchants from '../MainContent/Merchants';
import AddMerchant from '../MainContent/Merchants/addMerchant';
import Orders from '../MainContent/Orders';
import OrderView from '../MainContent/Orders/orderView';
import CMS from '../MainContent/CMS';
import AddCMS from '../MainContent/CMS/addCmsPage';
import TrackShipment from '../MainContent/Orders/trackShipment';
import Settings from '../MainContent/Settings';
import AllocateShipment from '../MainContent/Drivers/allocateShipment';
import DriverLoadTransfer from '../MainContent/Drivers/driverLoadTransfer';
import DeliveryCharges from '../MainContent/DeliveryCharges';
import AddDeliveryCharges from '../MainContent/DeliveryCharges/addCharges';
import CustomerAddresses from '../MainContent/Customers/addresses';
import MerchantAddresses from '../MainContent/Merchants/addresses';
import UsersOrders from '../MainContent/Orders/usersOrders';
import CountryList from '../MainContent/Country';
import AddCountry from '../MainContent/Country/addCountry';
import CityList from '../MainContent/City';
import AddCity from '../MainContent/City/addCity';
import TimeSlots from '../MainContent/Timeslots';
import AddTimeSlots from '../MainContent/Timeslots/addTimeslot';
import EditTimeSlots from '../MainContent/Timeslots/editTimeSlot';
import DriverCommissions from '../MainContent/DriverCommision';
import AddDriverCommissions from '../MainContent/DriverCommision/addCommission';
import DriversBookings from '../MainContent/Drivers/bookings';
import VendorBookings from '../MainContent/Merchants/bookings';
import DriverPaymentList from '../MainContent/Drivers/paymentList';
import AddDriverPayment from '../MainContent/Drivers/addPayment';
import VendorPaymentList from '../MainContent/Merchants/paymentList';
import AddVendorPayment from '../MainContent/Merchants/addPayment';
import AdminProfile from '../MainContent/Profile/profile';
import Admins from '../MainContent/Admins';
import AddAdmins from '../MainContent/Admins/addAdmin';
import OrderReports from '../MainContent/Merchants/orderReports';
import MerchantUsers from '../MainContent/Merchants/users';
import EditUser from '../MainContent/Merchants/editUser';

import { Route, Switch } from 'react-router-dom';
class mainbuilder extends Component {
  render() {
    return (
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/signin" exact component={Register} />
        <Route path="/forget-password" exact component={ForgetPassword} />
        <Route path="/drivers" exact component={Drivers} />
        <Route path="/add-drivers" exact component={AddDriver} />
        <Route path="/edit-driver/:driverId" exact component={AddDriver} />
        <Route path="/customers" exact component={Customers} />
        <Route path="/add-customers" exact component={AddCustomer} />
        <Route path="/edit-customer/:customerId" exact component={AddCustomer} />
        <Route path="/merchants" exact component={Merchants} />
        <Route path="/add-merchants" exact component={AddMerchant} />
        <Route path="/edit-merchant/:merchantId" exact component={AddMerchant} />
        <Route path="/orders" exact component={Orders} />
        <Route path="/pending-orders" exact component={Orders} />
        <Route path="/order-view/:orderId" exact component={OrderView} />
        <Route path="/cms-pages" exact component={CMS} />
        <Route path="/add-page" exact component={AddCMS} />
        <Route path="/edit-page/:pageId" exact component={AddCMS} />
        <Route path="/track-shipment" exact component={TrackShipment} />
        <Route path="/track-shipment/:shipmentId" exact component={TrackShipment} />
        <Route path="/settings" exact component={Settings} />
        <Route path="/allocate-shipment" exact component={AllocateShipment} />
        <Route path="/driver-load-transfer" exact component={DriverLoadTransfer} />
        <Route path="/delivery-charges" exact component={DeliveryCharges} />
        <Route path="/delivery-charges/add" exact component={AddDeliveryCharges} />
        <Route path="/delivery-charges/edit/:id" exact component={AddDeliveryCharges} />
        <Route path="/customers/address/:customerId" exact component={CustomerAddresses} />
        <Route path="/merchants/address/:vendorId" exact component={MerchantAddresses} />
        <Route path="/users-orders/:userId" exact component={UsersOrders} />
        <Route path="/country-list" exact component={CountryList} />
        <Route path="/add-country" exact component={AddCountry} />
        <Route path="/edit-country/:countryId" exact component={AddCountry} />
        <Route path="/city-list" exact component={CityList} />
        <Route path="/add-city" exact component={AddCity} />
        <Route path="/edit-city/:cityId" exact component={AddCity} />
        <Route path="/timeslots" exact component={TimeSlots} />
        <Route path="/add-timeslot" exact component={AddTimeSlots} />
        <Route path="/edit-timeslot/:slotId" exact component={EditTimeSlots} />
        <Route path="/driver-commission" exact component={DriverCommissions} />
        <Route path="/driver-commission/add" exact component={AddDriverCommissions} />
        <Route path="/driver-commission/edit/:commissionId" exact component={AddDriverCommissions} />
        <Route path="/driver-bookings" exact component={DriversBookings} />
        <Route path="/vendor-bookings" exact component={VendorBookings} />
        <Route path="/driver-payment-list" exact component={DriverPaymentList} />
        <Route path="/add-driver-payment" exact component={AddDriverPayment} />
        <Route path="/edit-driver-payment/:paymentid" exact component={AddDriverPayment} />
        <Route path="/vendor-payment-list" exact component={VendorPaymentList} />
        <Route path="/add-vendor-payment" exact component={AddVendorPayment} />
        <Route path="/edit-vendor-payment/:paymentid" exact component={AddVendorPayment} />
        <Route path="/profile" exact component={AdminProfile} />
        <Route path="/admins" exact component={Admins} />
        <Route path="/add-admin" exact component={AddAdmins} />
        <Route path="/edit-admin/:adminId" exact component={AddAdmins} />
        <Route path="/order-reports/:vendorId" exact component={OrderReports} />
        <Route path="/merchant-users/:vendorId" exact component={MerchantUsers} />
        <Route path="/edit-users/:userId" exact component={EditUser} />
        <Route path="/" component={Dashboard} />
      </Switch>
    );
  }
}

export default mainbuilder;