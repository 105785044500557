import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';
import SweetAlert from 'react-bootstrap-sweetalert';

const columns = [
  {
    label: 'Country',
    field: 'country',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'City',
    field: 'city',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Customer charges',
    field: 'customercharges',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Merchant charges',
    field: 'merchantcharges',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Kurbi fee',
    field: 'kurbifee',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled'
  }
];


class DeliveryCharges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryCharges: [],
      rows: [],
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      chargeId: null,
      showDelete: false
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Delivery Charges');
    this.getCharges(1);
  }

  getCharges = async (currentPage) => {
    const { limit } = this.state;
    const pageNo = currentPage || 1;
    const endPoint = `${Api.getDeliveryCharges}/${pageNo}/${limit}`;
    const callback = AuthApi.getDataFromServer;
    const { data, message } = await callback(endPoint);
    if (data) {
      this.setState({
        deliveryCharges: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      NotificationManager.error(message);
    }
  }

  setTableData = () => {
    const { deliveryCharges } = this.state;
    const rows = [];
    deliveryCharges.map((charges) => { // eslint-disable-line
      rows.push({
        country: charges.countryname_eng,
        city: charges.cityname_eng,
        customercharges: `$${charges.customercharges}`,
        merchantcharges: `$${charges.vendorcharges}`,
        kurbifee: charges.kurbicharges ? `$${charges.kurbicharges || '0'}` : '-',
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/delivery-charges/edit/${charges.cityid}`)
            }}>
              <i className="mdi mdi-lead-pencil" id="editDriver" title="Edit charges"></i>
            </a>
            <a href="/" className="m-l-10" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.setState({ showDelete: true, chargeId: charges.id })
            }}>
              <i className="mdi mdi-delete" title="Delete driver"></i>
            </a>
          </React.Fragment>
        )
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();

  }

  deleteCharge = async () => {
    const { chargeId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(`${Api.deleteDeliveryCharge}/${chargeId}`);
    if (data) {
      this.setState({ showDelete: false, chargeId: null }, () => {
        this.getCharges(1);
      });
      NotificationManager.success('Delivery charge deleted');
    } else {
      NotificationManager.error(message);
    }
  }

  render() {
    const { rows, showDelete, keyword, totalCount, currentPage, limit } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
          {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.deleteCharge() }}
                  onCancel={() => { this.setState({ showDelete: false, chargeId: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getCharges}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default DeliveryCharges;