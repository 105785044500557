import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';
import SharedService from '../../../helper/SharedService';
import BankDetails from '../../../components/BankDetails';

const columns = [
  {
    label: 'Name',
    field: 'name',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Email',
    field: 'email',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Mobile',
    field: 'mobile',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Weekly earning',
    field: 'weeklyearning',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Registration date',
    field: 'regdate',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Skip payment',
    field: 'skippayment',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled'
  }
];

class Merchants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchants: [],
      rows: [],
      merchantId: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      countryid: '',
      cityid: '',
      countries: [],
      cities: [],
      showCountryCityFilter: true,
      bankDetails: {},
      hasDetails: true
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('All Merchants');
    this.getCountries();
    this.checkAppliedFilter();
    this.subscribed = SharedService.cityChanged.subscribe(this.checkAppliedFilter);
  }

  componentWillUnmount = () => {
    if (this.subscribed) this.subscribed.unsubscribe();
  }

  checkAppliedFilter = async () => {
    const countryid = await localStorage.getItem('admincountry') || '';
    const cityid = await localStorage.getItem('admincity') || '';
    const showCountryCityFilter = cityid ? false : true;
    this.setState({ countryid, cityid, showCountryCityFilter }, () => {
      this.getMerchants(1);
    })
  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryid}`);
    if (data && data.status !== "Fail") {
      this.setState({ cities: data.data });
    }
  }

  getMerchants = async (currentPage) => {
    const { keyword, limit, cityid, countryid } = this.state;
    const pageNo = currentPage || 1;
    const hasSearch = (keyword !== '') || (countryid.toString().trim() !== '') || (cityid.toString().trim() !== '')
    const endPoint = (hasSearch) ? `${Api.searchVendor}` : `${Api.getMerchants}/${pageNo}/${limit}`;
    const searchPayload = {
      "searchfield": keyword,
      "skip": pageNo,//(currentPage - 1) * limit,
      "limit": limit
    };
    if (cityid.toString().trim() !== '') searchPayload.cityid = cityid;
    if (countryid.toString().trim() !== '') searchPayload.countryid = countryid;
    const callback = (hasSearch) ? AuthApi.postDataToServer : AuthApi.getDataFromServer;

    const { data, message, status } = await callback(endPoint, searchPayload);
    if (data) {
      this.setState({
        merchants: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      if (status === 401) {
        this.props.history.push('/login');
        return;
      }
      NotificationManager.error(message);
    }
  }

  changeMerchantStatus = async (merchant) => {
    const { currentPage } = this.state;
    const payload = {
      "vendorid": merchant.vendorid,
      "userstatus": merchant.isactive ? 0 : 1
    };

    const { data, message } = await AuthApi.postDataToServer(Api.changeVendorStatus, payload);
    if (data && data.status !== 'Fail') {
      NotificationManager.success('Vendor status changed');
      this.getMerchants(currentPage);
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  changeMerchantPaymentStatus = async (merchant) => {
    const { currentPage } = this.state;
    const payload = {
      "vendorid": merchant.vendorid,
      "skippayment": merchant.skippayment ? 0 : 1
    };

    const { data, message } = await AuthApi.putDataToServer(Api.vendorSkipPayment, payload);
    if (data && data.status !== 'Fail') {
      NotificationManager.success('Vendor skip payment status changed');
      this.getMerchants(currentPage);
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  getBankDetails = async (vendor) => {
    const { data, message } = await AuthApi.getDataFromServer(`${Api.getBankDetails}/${vendor.id}`);
    if (data && data.status !== "Fail") {
      this.setState({ bankDetails: data.data, hasDetails: true });
    } else {
      this.setState({ bankDetails: {}, hasDetails: false });
    }
  }

  setTableData = () => {
    const { merchants } = this.state;
    const rows = [];
    merchants.map((merchant, i) => { // eslint-disable-line
      rows.push({
        name: `${merchant.first_name} ${merchant.last_name}`,
        email: `${merchant.email}`,
        mobile: `${merchant.mobile}`,
        weeklyearning: `${merchant.currencysymbol || ''} ${merchant.weeklyearning || 0}`,
        regdate: '-',
        status: (
          <React.Fragment>
            <input type="checkbox" id={`switch${merchant.vendorid}`} switch="info" checked={merchant.isactive ? "checked" : ""} onChange={() => {
              this.changeMerchantStatus(merchant);
            }} />
            <label for={`switch${merchant.vendorid}`} data-on-label="" data-off-label=""></label>
          </React.Fragment>
        ),
        skippayment: (
          <React.Fragment>
            <input type="checkbox" id={`skipswitch${merchant.vendorid}`} switch="info" checked={merchant.skippayment ? "checked" : ""} onChange={() => {
              this.changeMerchantPaymentStatus(merchant);
            }} />
            <label for={`skipswitch${merchant.vendorid}`} data-on-label="" data-off-label=""></label>
          </React.Fragment>
        ),
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/edit-merchant/${merchant.vendorid}`)
            }}>
              <i className="mdi mdi-lead-pencil" id="editDriver" title="Edit Merchant"></i>
            </a>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/merchants/address/${merchant.vendorid}`)
            }}>
              <i className="mdi mdi-note-text" id="showAddresses" title="Address list"></i>
            </a>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/order-reports/${merchant.vendorid}`)
            }}>
              <i className=" mdi mdi-receipt" id="orderReports" title="Order Reports"></i>
            </a>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/users-orders/${merchant.id}`)
            }}>
              <i className="mdi mdi-calendar-text" title="Orders"></i>
            </a>
            <a href="/" className="" data-toggle="modal" data-target="#bank-details" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.getBankDetails(merchant);
              // this.props.history.push(`/edit-driver/${driver.driverid}`)
            }}>
              <i className="mdi mdi-credit-card" title="Bank Details"></i>
            </a>
            <a href="/" className="" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              // this.setState({ showDelete: true, merchantId: merchant.vendorid })
              this.props.history.push(`/merchant-users/${merchant.vendorid}`);
            }}>
              <i className="fa fa-users" title="Users"></i>
            </a>
            <a href="/" className="" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.setState({ showDelete: true, merchantId: merchant.vendorid })
            }}>
              <i className="mdi mdi-delete text-danger" title="Delete merchant"></i>
            </a>
          </React.Fragment>
        )
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }

  deteleMerchant = async () => {
    const { merchantId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(`${Api.deleteVendor}/${merchantId}`);
    if (data) {
      this.setState({ showDelete: false, merchantId: null }, () => {
        this.getMerchants();
      });
      NotificationManager.success('Merchant deleted');
    } else {
      NotificationManager.error(message);
    }
  }

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getMerchants(1);
  }

  clearSearch = () => {
    const { showCountryCityFilter, cityid, countryid } = this.state;
    const city = showCountryCityFilter ? '' : cityid;
    const country = showCountryCityFilter ? '' : countryid;
    this.setState({ keyword: '', cityid: city, countryid: country }, () => {
      this.getMerchants(1);
    })
  }

  render() {
    const { rows, showDelete, keyword, limit, totalCount, currentPage, showCountryCityFilter, countries, countryid, cities, cityid, bankDetails, hasDetails } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.deteleMerchant() }}
                  onCancel={() => { this.setState({ showDelete: false, merchantId: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.onSearchSubmit}>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <input className="form-control" value={keyword} type="text" placeholder="Search here" id="example-text-input" onChange={(e) => { this.setState({ keyword: e.target.value }) }} />
                        </div>
                        {
                          showCountryCityFilter && (
                            <React.Fragment>

                              <div className="col-sm-3">
                                <select className="form-control" value={countryid} onChange={(e) => {
                                  this.setState({ countryid: e.target.value, cityid: '' });
                                  this.getCities(e.target.value);
                                }}>
                                  <option value=''>Select country</option>
                                  {
                                    countries.map((country) => (
                                      <option value={country.countryid}>{country.countryname_eng}</option>
                                    ))
                                  }
                                </select>
                              </div>
                              <div className="col-sm-3">
                                <select className="form-control" value={cityid} onChange={(e) => {
                                  this.setState({ cityid: e.target.value });
                                  // this.getCities(e.target.value);
                                }}>
                                  <option value=''>Select city</option>
                                  {
                                    cities.map((city) => (
                                      <option value={city.cityid}>{city.cityname_eng}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </React.Fragment>
                          )
                        }
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Search
                        </button>
                          <button type="reset" onClick={() => { this.clearSearch(); }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      responsive
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getMerchants}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BankDetails
          bankDetails={bankDetails}
          hasDetails={hasDetails}
        />
      </AUX>
    );
  }
}

export default withRouter(Merchants);
