import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import MaterialInput from '@material-ui/core/Input';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import Geocode from "react-geocode";
import constants from '../../../helper/constants';
import './style.css';
import PlaceSearch from '../../../components/PlaceSearch';

Geocode.setApiKey(constants.mapKey);

class AddMerchant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      mail: '',
      storeName: '',
      password: '',
      mobile: '',
      countrycode: '',
      countryid: '',
      cityid: '',
      confirmPassword: '',
      fnameErr: '',
      lnameErr: '',
      mailErr: '',
      mobileErr: '',
      countrycodeErr: '',
      countryErr: '',
      cityErr: '',
      passErr: '',
      cpassErr: '',
      storeNameErr: '',
      roleid: 2,
      merchantId: null,
      countries: [],
      cities: [],
      addr1: '',
      formattedAddress: '',
      geoLocErr: '',
      geoLocation: {},
      placeInText: '',
      setPickupBound: {},
      skippayment: 0,
      amountErr: '',
      amount: ''
    }
  }

  componentDidMount = () => {
    const merchantId = this.props.match.params.merchantId;
    this.setState({ merchantId });
    this.getCountries();
    if (merchantId) {
      this.getMerchantData(merchantId);
      customFunctions.setTitle('Edit Merchant');
    } else {
      customFunctions.setTitle('Add Merchant');
    }
  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryid}`);
    if (data && data.status !== "Fail") {
      this.setState({ cities: data.data });
    }
  }

  getMerchantData = async (merchantId) => {
    const { data, message } = await AuthApi.getDataFromServer(`${Api.getVendor}${merchantId}`);
    if (data && data.data) {
      var placeInText = `in ${data.data.cityname_eng} ${data.data.countryname_eng}`;
      const latLng = data.data.pickuplonglat.split(',');
      this.setState({
        firstname: data.data.first_name,
        lastname: data.data.last_name,
        mail: data.data.email,
        mobile: data.data.mobile,
        storeName: data.data.vendorname,
        countryid: data.data.countryid ? data.data.countryid.toString() : '',
        cityid: data.data.cityid ? data.data.cityid.toString() : '',
        countrycode: data.data.countrycode ? data.data.countrycode.toString() : '',
        placeInText,
        addr1: data.data.pickuplocationaddress1,
        formattedAddress: data.data.pickuplocationaddress1,
        geoLocation: { lat: latLng[0], lng: latLng[1] },
        pickuplocationmasterid: data.data.pickuplocationmasterid,
        amount: data.data.deliverycharges ? String(data.data.deliverycharges) : '',
        skippayment: data.data.skippayment
      }, async () => {
        await this.setMapBound();
        if (this.mapRef) {
          this.mapRef.map.setZoom(19);
          this.mapRef.map.panTo({ lat: Number(latLng[0]), lng: Number(latLng[1]) });
        }
      });
      if (data.data.countryid) {
        this.getCities(data.data.countryid);
      }
    } else {
      var $this = this;
      NotificationManager.error(message, '', 1000);
      setTimeout(() => {
        $this.props.history.push('/merchants');
      }, 1000);
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const { firstname, lastname, mail, mobile, countryid, cityid, countrycode, password, confirmPassword, merchantId, storeName, geoLocation, amount } = this.state;
    let fnameErr = '', lnameErr = '', mailErr = '', mobileErr = '', passErr = '', cpassErr = '', storeNameErr = '', countryErr = '', cityErr = '', countrycodeErr = '', geoLocErr = '', amountErr = '';
    let isValid = true;
    //console.log(mobile);
    if (firstname.trim() === '') {
      fnameErr = 'First name is required'; isValid = false
    }

    if (lastname.trim() === '') {
      lnameErr = 'Last name is required'; isValid = false
    }

    if (mail.trim() === '') {
      mailErr = 'Email is required'; isValid = false;
    } else if (!customFunctions.validateEmail(mail)) {
      mailErr = 'Invalid email'
    }

    if (countryid.trim() === '') {
      countryErr = 'Country is required'; isValid = false
    }

    if (cityid.trim() === '') {
      cityErr = 'City is required'; isValid = false
    }

    if (countrycode.trim() === '') {
      countrycodeErr = 'Country code is required'; isValid = false
    }

    if (String(mobile).trim() === '') {
      mobileErr = 'Mobile is required'; isValid = false;
    } else if (!customFunctions.validatePhoneNumber(String("+" + countrycode + " " + mobile))) {
      mobileErr = 'Invalid mobile'; isValid = false;
    }

    if (storeName.trim() === '') {
      storeNameErr = 'Store name is required'; isValid = false;
    }

    if ((!merchantId) || (merchantId && (password.trim() !== '' || confirmPassword.trim() !== ''))) {
      if (password.trim() === '') {
        passErr = 'Password is required'; isValid = false;
      }

      if (confirmPassword.trim() === '') {
        cpassErr = 'Password confirmation is required'; isValid = false;
      } else if (password !== confirmPassword) {
        cpassErr = 'Password did not match'; isValid = false;
      }
    }

    if ((!geoLocation || !geoLocation.lat)) {
      geoLocErr = 'Geolocation required'; isValid = false;
    }

    if (amount.trim() === '') {
      amountErr = 'Delivery charges are required'; isValid = false;
    }

    this.setState({ fnameErr, lnameErr, mailErr, mobileErr, passErr, cpassErr, storeNameErr, countryErr, cityErr, countrycodeErr, geoLocErr, amountErr });
    if (isValid) {
      this.addMerchant()
    }
  }

  addMerchant = async () => {
    const { firstname, lastname, mail, mobile, password, roleid, merchantId, storeName, countryid, cityid, addr1, geoLocation, countrycode, pickuplocationmasterid, amount, skippayment } = this.state;
    var selectedLoc = '';
    if ((typeof geoLocation.lat === 'string') || (typeof geoLocation.lat === 'number')) {
      selectedLoc = `${geoLocation.lat},${geoLocation.lng}`;
    } else {
      selectedLoc = `${geoLocation.lat()},${geoLocation.lng()}`;
    }
    const postData = {
      firstname,
      lastname,
      // mail,
      mobile: String(mobile),
      vendorname: storeName,
      countryid: parseInt(countryid),
      cityid: parseInt(cityid),
      countrycode: parseInt(countrycode),
      pickuplocationaddress1: addr1,
      pickuplatlong: selectedLoc,
      deliverycharges: amount,
      skippayment
      // password,
      // roleid
    };
    const callback = merchantId ? AuthApi.putDataToServer : AuthApi.postDataToServer;
    const endPoint = merchantId ? Api.updateVendor : Api.addVendor;
    if (merchantId) {
      postData.vendorid = parseInt(merchantId);
      postData.pickuplocationmasterid = pickuplocationmasterid;
    } else {
      postData.sendemail = 0;
      postData.mail = mail;
      postData.password = password;
      postData.roleid = roleid;
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.status !== "Fail") {
      if (merchantId) {
        var $this = this;
        NotificationManager.success('Merchant edited', '', 900);
        setTimeout(() => {
          $this.props.history.push('/merchants');
        }, 1000);
        return;
      }
      NotificationManager.success('Merchant added');
      this.resetData();
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  resetData = () => {
    this.setState({
      firstname: '', lastname: '', mail: '', password: '', mobile: '', countryid: '', cityid: '', countrycode: '', confirmPassword: '', fnameErr: '', lnameErr: '', mailErr: '', mobileErr: '', passErr: '', cpassErr: '', roleid: 2, storeNameErr: '', storeName: '', countryErr: '', cityErr: '', countrycodeErr: '',
      geoLocation: {}, formattedAddress: '', placeInText: '', addr1: '', skippayment: 0, amount: ''
    });
  }

  setMapBound = async () => {
    let setboundLoc = this.state.placeInText;
    var latlong = await this.getLatLogFromAddress(setboundLoc);
    if (latlong) {
      let bounds = (latlong.geometry.bounds !== undefined) ? latlong.geometry.bounds : latlong.geometry.viewport;
      var points = [
        bounds.northeast,
        bounds.southwest
      ]
      var boundsArr = new this.props.google.maps.LatLngBounds()
      for (var i = 0; i < points.length; i++) {
        boundsArr.extend(points[i]);
      }
      this.setState({ setPickupBound: boundsArr });
    } else {
      this.setState({ setPickupBound: '' });
    }

  }


  setVendorAddress = (place) => {
    var selectedPlace = place;
    this.setState({
      formattedAddress: place.formattedAddress,
      geoLocation: place.geoLocation,
      addr1: place.formattedAddress,
      // addr2: place.formattedAddress,
      // addr3: place.formattedAddress,
    }, () => {
      if (this.mapRef) {
        this.mapRef.map.setZoom(19);
        this.mapRef.map.panTo(selectedPlace.geoLocation);
      }
    });
  }


  getLatLogFromAddress = async (address) => {
    var latLng = '';
    if (!address) return '';

    latLng = new Promise(async (resolve, reject) => {
      await Geocode.fromAddress(address).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          resolve(response.results[0]);
        },
        error => {
          console.error('geocode errror', error);
          resolve('');
        }
      );
    });
    return latLng;
  }

  onMapClick = (e, marker, event) => {
    // console.log(event.latLng.lat());//e.latLng.lng()
    const { setPickupBound } = this.state;
    const { formattedAddress, addr1 } = this.state;
    const selectedLocation = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    const { google } = this.props;
    var geocoder = new google.maps.Geocoder();
    if (setPickupBound.contains(selectedLocation)) {
      // location within country or city
    } else {

      NotificationManager.error('Cannot select this area');
      return;
    }

    var $this = this;
    geocoder.geocode({
      location: selectedLocation,
      // componentRestrictions: {
      //   country: "AU",
      //   postalCode: "2000"
      // }
    }, function (results, status) {
      let formedAddress = '', addrLine1 = '';
      if (status === "OK") {
        if (results && results[0]) {
          formedAddress = results[0].formatted_address;
          addrLine1 = results[0].formatted_address;
        }
        formedAddress = formedAddress.trim() === '' ? formattedAddress : formedAddress;
        addrLine1 = addrLine1.trim() === '' ? addr1 : addrLine1;
        $this.setState({ geoLocation: selectedLocation, selectedLocation, formattedAddress: formedAddress, addr1: addrLine1 });

        if ($this.mapRef) {
          // $this.mapRef.map.setZoom(11);
          // $this.mapRef.map.setCenter(selectedLocation);
        }
      } else {
        window.alert(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });

  }


  render() {
    const {
      firstname, lastname, mail, mobile, password, countryid, cityid, countrycode, confirmPassword, fnameErr, lnameErr, mobileErr, mailErr, passErr, cpassErr, merchantId, storeNameErr, storeName, countryErr, cityErr, countrycodeErr, countries, cities, addr1, setPickupBound, geoLocation, formattedAddress, placeInText, geoLocErr, skippayment, amount, amountErr
    } = this.state;
    const { google } = this.props;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">First Name*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={firstname} type="text" placeholder="John" id="example-text-input" onChange={(e) => { this.setState({ firstname: e.target.value }) }} />
                          <span id="err">{fnameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-search-input" className="col-sm-2 col-form-label">Last Name*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={lastname} type="text" placeholder="Doe" id="example-search-input" onChange={(e) => { this.setState({ lastname: e.target.value }) }} />
                          <span id="err">{lnameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-email-input" className="col-sm-2 col-form-label">Email*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={mail} type="text" placeholder="user@example.com" id="example-email-input" onChange={(e) => { this.setState({ mail: e.target.value }) }} disabled={merchantId} />
                          <span id="err">{mailErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Country*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={countryid} onChange={(e) => {
                            const selectedCountry = countries.filter((c) => { return Number(c.countryid) === Number(e.target.value) })
                            this.setState({ countryid: e.target.value, cityid: '', placeInText: `in ${selectedCountry[0].countryname_eng}` }, () => {
                              this.setMapBound();
                            });
                            this.getCities(e.target.value);
                          }}>
                            <option value=''>Select country</option>
                            {
                              countries.map((country) => (
                                <option value={country.countryid}>{country.countryname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{countryErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">City*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={cityid} onChange={(e) => {
                            const selectedCountry = countries.filter((c) => { return Number(c.countryid) === Number(countryid) })
                            const scid = selectedCountry.length > 0 ? selectedCountry[0].countryname_eng : '';
                            const selectedCity = cities.filter((city) => { return Number(city.cityid) === Number(e.target.value) })
                            this.setState({ cityid: e.target.value, placeInText: `${scid} ${selectedCity[0].cityname_eng}` }, () => {
                              this.setMapBound();
                            });
                            // this.getCities(e.target.value);
                          }}>
                            <option value=''>Select city</option>
                            {
                              cities.map((city) => (
                                <option value={city.cityid}>{city.cityname_eng}</option>
                              ))
                            }
                          </select>
                          <span id="err">{cityErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Country Code*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={countrycode} onChange={(e) => {
                            this.setState({ countrycode: e.target.value });
                          }}>
                            <option value=''>Select country code</option>
                            {
                              countries.map((country) => (
                                <option value={country.countrycode}>+{country.countrycode}</option>
                              ))
                            }
                          </select>
                          <span id="err">{countrycodeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Mobile*</label>
                        <div className="col-sm-5">
                          {/* <InputMask mask="9999999999" value={mobile} className="form-control" onChange={(e) => { this.setState({ mobile: e.target.value }) }}>
                            {(inputProps) => <MaterialInput {...inputProps} placeholder="1234567890" type="tel" disableUnderline />}
                          </InputMask> */}
                          <input type="text" className="form-control" value={mobile} placeholder="9999999999"
                            onChange={(e) => {
                              var val = e.target.value;
                              var phoneformat = /^\d{1,15}$/;
                              if (val === '' || val.match(phoneformat)) {
                                this.setState({ mobile: val })
                              }
                            }}
                          />
                          <span id="err">{mobileErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-storename-input" className="col-sm-2 col-form-label">Store name*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={storeName} type="text" placeholder="Store name" id="example-storename-input" onChange={(e) => { this.setState({ storeName: e.target.value }) }} />
                          <span id="err">{storeNameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-storename-input" className="col-sm-2 col-form-label">Store address*</label>
                        <div className="col-sm-5">
                          <PlaceSearch
                            classes="form-control"
                            placeholder="Enter store location or select from the map"
                            placeValue={formattedAddress}
                            onPlaceSelect={(place) => {
                              this.setVendorAddress(place)
                              // this.setState({ storeGeoLocation: place.geometry.location })
                            }}
                            google={google}
                            onValueChange={(val, loc = {}) => { this.setState({ formattedAddress: val, addr1: val, geoLocation: loc }) }}
                            inText={placeInText}
                            disabled={((countryid === '' || cityid === ''))}
                          />
                          <span id="err">{geoLocErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-2" />
                        <div className="col-sm-5 mt- d-flex">
                          <div className="map-wrapper">
                            <Map
                              restriction={{
                                latLngBounds: setPickupBound,
                                strictBounds: false
                              }}
                              bounds={setPickupBound}
                              ref={(r) => this.mapRef = r}
                              google={google}
                              style={{ "height": "300px", "width": "100%", "z-index": "89" }}
                              //center={geoLocation}
                              onClick={(e, marker, event) => {
                                if (((countryid === '' && cityid === ''))) return;
                                this.onMapClick(e, marker, event);
                              }}
                              zoom={19}
                            >
                              {
                                geoLocation && geoLocation.lat && geoLocation.lng && (
                                  <Marker
                                    position={geoLocation}
                                  />
                                )
                              }
                            </Map>
                          </div>
                        </div>


                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Skip Payment</label>
                        <div className="col-sm-5">
                          <input type="checkbox" id={`skipswitch`} switch="info" checked={skippayment ? "checked" : ""} onChange={() => {
                            this.setState({ skippayment: skippayment ? 0 : 1 });
                          }} />
                          <label for="skipswitch" data-on-label="" data-off-label=""></label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-text-customer-charges" className="col-sm-2 col-form-label">Delivery charges*</label>
                        <div className="col-sm-5">
                          <div className="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">$</span>
                            </div>
                            <input className="form-control" value={amount} type="text" placeholder="50" id="example-text-customer-charges" onChange={(e) => {
                              if (customFunctions.validateAmount(e.target.value) || e.target.value === '') {
                                this.setState({ amount: e.target.value })
                              }
                            }} />
                          </div>
                          <span id="err">{amountErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-url-input" className="col-sm-2 col-form-label">Password
                        {!merchantId && (<React.Fragment>*</React.Fragment>)}</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={password} type="password" placeholder="*****" id="example-url-input" onChange={(e) => { this.setState({ password: e.target.value }) }} />
                          <span id="err">{passErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-tel-input" className="col-sm-2 col-form-label">Confirm Password
                        {!merchantId && (<React.Fragment>*</React.Fragment>)}</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={confirmPassword} type="password" placeholder="*****" id="example-tel-input" onChange={(e) => { this.setState({ confirmPassword: e.target.value }) }} />
                          <span id="err">{cpassErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                        </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/merchants');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

// export default withRouter(AddMerchant);

export default GoogleApiWrapper({
  // apiKey: 'AIzaSyD46yuo9rWxFv9luFDL8ua6OMdiymeL0fY'
  apiKey: constants.mapKey
})(AddMerchant);
