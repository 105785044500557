import { Component } from 'react';

export default class Api extends Component {
  static baseUrl = Api.getBaseUrl();
  static loginUrl = `${Api.baseUrl}admin/login`;
  static addUser = `${Api.baseUrl}users/addUser`;
  static addCustomer = `${Api.baseUrl}customer/register`;
  static addVendor = `${Api.baseUrl}vendor/register`;
  static addDriver = `${Api.baseUrl}driver/register`;
  static getDrivers = `${Api.baseUrl}driver/getAllDrivers`;
  static getCustomers = `${Api.baseUrl}customer/getAllCustomers`;
  static getMerchants = `${Api.baseUrl}vendor/getAllVendors`;
  static userById = `${Api.baseUrl}users/getUserById/`;
  static editUser = `${Api.baseUrl}users/editUser`;
  static ordersUrl = `${Api.baseUrl}consignmentmanifest/getAllShipments`;
  static allocateShipmentUrl = `${Api.baseUrl}consignmentmanifest/allocateshipment`;
  static getShipmentsUrl = `${Api.baseUrl}consignmentmanifest/getShipmentsToAllocate`;
  static searchVendor = `${Api.baseUrl}vendor/search`;
  static searchCustomer = `${Api.baseUrl}customer/search`;
  static searchDriver = `${Api.baseUrl}driver/search`;
  static saveConfig = `${Api.baseUrl}admin/saveconfig`;
  static getConfig = `${Api.baseUrl}admin/config`;
  static changeDriverStatus = `${Api.baseUrl}driver/changeStatus`;
  static changeCustomerStatus = `${Api.baseUrl}customer/changeStatus`;
  static changeVendorStatus = `${Api.baseUrl}vendor/changeStatus`;
  static getCustomer = `${Api.baseUrl}customer/getCustomerById/`;
  static getVendor = `${Api.baseUrl}vendor/getVendorById/`;
  static getDriver = `${Api.baseUrl}driver/getDriverById/`;
  static updateCustomer = `${Api.baseUrl}customer/editCustomer`;
  static updateVendor = `${Api.baseUrl}vendor/editVendor`;
  static updateDriver = `${Api.baseUrl}driver/editDriver`;
  static trackShipment = `${Api.baseUrl}consignmentmanifest/trackShipment/`;
  static getCmsPages = `${Api.baseUrl}cmspages/getAllPageData`;
  static getSingleCmsPage = `${Api.baseUrl}cmspages/getOnePageData`;
  static addCmsPage = `${Api.baseUrl}cmspages/addpagedata`;
  static updateCmsPage = `${Api.baseUrl}cmspages/updatePageData`;
  static getShipmentDetails = `${Api.baseUrl}consignmentmanifest/getShipmentDetails`;
  static getDeliveryCharges = `${Api.baseUrl}utility/getDeliverycharge`;
  static getCountries = `${Api.baseUrl}utility/getCountyList`;
  static getCities = `${Api.baseUrl}utility/getCityList`;
  static addDeliveryCharges = `${Api.baseUrl}utility/addDeliverycharge`;
  static updateDeliveryCharges = `${Api.baseUrl}utility/updateDeliverycharge`;
  static getDeliverychargeByCity = `${Api.baseUrl}utility/getDeliverychargeByCity`;
  static getCustomerAddress = `${Api.baseUrl}address/getUserAddress`;
  static getVendorAddress = `${Api.baseUrl}vendor/getVendorAddress`;
  static getUsersOrders = `${Api.baseUrl}users/getUserOrders`;
  static getDashboardData = `${Api.baseUrl}dashbord/getAdminDashbord`;
  static getPendingShipmentPickup = `${Api.baseUrl}consignmentmanifest/getPickupPendingList`;
  static getAllCountyList = `${Api.baseUrl}utility/getAllCountyList`;
  static getAllCityList = `${Api.baseUrl}utility/getAllCityList`;
  static getCityById = `${Api.baseUrl}utility/getCityById`;
  static getCountryById = `${Api.baseUrl}utility/getCountryById`;
  static updatecountry = `${Api.baseUrl}utility/updatecountry`;
  static updatecity = `${Api.baseUrl}utility/updatecity`;
  static addcountry = `${Api.baseUrl}utility/addcountry`;
  static addcity = `${Api.baseUrl}utility/addcity`;
  static getTimeSlots = `${Api.baseUrl}utility/gettimeslots`;
  static addTimeSlots = `${Api.baseUrl}utility/addtimeslots`;
  static updateTimeSlots = `${Api.baseUrl}utility/updatetimeslots`;
  static getTimeSlotById = `${Api.baseUrl}utility/gettimeslotsbyid/`;
  static setfirebasetoken = `${Api.baseUrl}utility/setfirebasetoken`;
  static getDriverCommisions = `${Api.baseUrl}driver/getDriverCommission`;
  static addCommission = `${Api.baseUrl}driver/addDriverCommission`;
  static updateCommission = `${Api.baseUrl}driver/updateDriverCommission`;
  static getCommissionByCity = `${Api.baseUrl}driver/getDriverCommissionByCity`;
  static getAdmin = `${Api.baseUrl}admin/getAdminById`;
  static searchCity = `${Api.baseUrl}utility/searchCity`;
  static deleteCity = `${Api.baseUrl}utility/deleteCity/`;
  static searchCountry = `${Api.baseUrl}utility/searchCountry`;
  static deleteCountry = `${Api.baseUrl}utility/deleteCountry/`;
  static getBankDetails = `${Api.baseUrl}utility/getBankInfoByUserId`;
  static deleteOrder = `${Api.baseUrl}consignmentmanifest/deleteShipment`;
  static deleteCustomer = `${Api.baseUrl}customer/deleteCustomer`;
  static deleteVendor = `${Api.baseUrl}vendor/deleteVendor`;
  static deleteDriver = `${Api.baseUrl}driver/deleteDriver`;
  static driverBooking = `${Api.baseUrl}driver/getBooking`;
  static vendorBooking = `${Api.baseUrl}vendor/getBooking`;
  static notificationCount = `${Api.baseUrl}notification/getnotificationcount`;
  static getDriverPaymentList = `${Api.baseUrl}admin/getDriverPaymentList`;
  static getVendorPaymentList = `${Api.baseUrl}admin/getVendorPaymentList`;
  static addPayment = `${Api.baseUrl}admin/addpayment`;
  static updatePayment = `${Api.baseUrl}admin/updatepayment`;
  static getPaymentDetails = `${Api.baseUrl}admin/getPaymentDetails`;
  static getAdmins = `${Api.baseUrl}admin/getAllAdmins`;
  static searchAdmin = `${Api.baseUrl}admin/search`;
  // static getAdmin = `${Api.baseUrl}admin/getAdminById/`;
  static updateAdmin = `${Api.baseUrl}admin/editAdmin`;
  static changeAdminStatus = `${Api.baseUrl}admin/changeStatus`;
  static addAdmin = `${Api.baseUrl}admin/register`;
  static deleteAdmin = `${Api.baseUrl}admin/deleteAdmin`;
  static updatePassword = `${Api.baseUrl}users/updatepassword`;
  static deleteTimeSlots = `${Api.baseUrl}utility/deletetimeslots`;
  static getAdminCityList = `${Api.baseUrl}utility/getAdminCityList`;
  static deleteDeliveryCharge = `${Api.baseUrl}utility/deleteDeliverycharges`;
  static getVendorReport = `${Api.baseUrl}vendor/vendorOrderReports`;
  static vendorExportReport = `${Api.baseUrl}vendor/exportVendorOrderReports`;
  static vendorSkipPayment = `${Api.baseUrl}vendor/vendorSkipPayment`;
  static getStoreUsers = `${Api.baseUrl}vendor/getUsersForStore/`;
  static driverTransferLoad = `${Api.baseUrl}driver/transferLoad`;
  static getUserStore = `${Api.baseUrl}vendor/getUserStore/`;
  static editVendorForStore = `${Api.baseUrl}vendor/editVendorForStore/`;
  static deleteVendorForStore = `${Api.baseUrl}vendor/deleteVendorForStore/`;
  static getSurcharges = `${Api.baseUrl}surcharge/getSurchargesByCityid/`;
  static addSurcharges = `${Api.baseUrl}surcharge/addSurchargesByCityid/`;
  static updateSurcharges = `${Api.baseUrl}surcharge/editSurchargesByid/`;
  static deleteSurcharges = `${Api.baseUrl}surcharge/deleteSurchargesById/`;

  constructor(props) {
    super(props);
    this.state = {};
    this.environment = 'dev';
    this.getBaseUrl = this.getBaseUrl.bind(this);
  }

  // Get base URL of APIs
  static getBaseUrl() {
    let url = '';
    switch (this.environment) {
      case 'production':
        url = '';
        break;
      // Default: development server
      default:
        // url = 'http://pickupanddeliveryapi.reviewtestlink.com:3030/api/';
        url = 'https://kso911i80f.execute-api.us-east-1.amazonaws.com/dev/api/';
        //url = 'https://85brgtkdy3.execute-api.ca-central-1.amazonaws.com/dev/api/';
        break;
    }
    return url;
  }

  environment;
}
