import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';
import orderData from '../../../helper/sampledata/orders';

class AllocateShipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      rows: [],
      driverId: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      drivers: [],
      selectedDriver: "",
      allChecked: false,
      columns: []
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Allocate Shipment');
    const userData = await customFunctions.getUserData();
    console.log('userData', userData);
    this.getOrders(1);
    this.getDrivers();
  }

  checkAll = () => {
    const { orders, allChecked } = this.state;
    for (let i = 0; i < orders.length; i++) {
      orders[i].isChecked = allChecked;
    }
    //console.log('orders', orders);
    this.setState({ orders }, () => {
      this.setColumns();
      this.setTableData();
    });
  }

  getDrivers = async () => {
    const endPoint = `${Api.getDrivers}/1/50`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        drivers: data.data,
      });
    } else {
      NotificationManager.error(message);
    }
  }

  getOrders = async (currentPage) => {
    const { keyword, limit } = this.state;
    const pageNo = currentPage || 1;
    const endPoint = `${Api.getShipmentsUrl}/${pageNo}/${limit}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        orders: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo,
        allChecked: false
      }, () => {
        this.setColumns();
        this.setTableData();
      });
    } else {
      NotificationManager.error(message);
    }
  }

  checkboxCallback = (index) => {
    const { orders } = this.state;
    orders[index].isChecked = !orders[index].isChecked;
    const uncheckedOrders = orders.filter(order => !order.isChecked)
    this.setState({ orders, allChecked: uncheckedOrders.length <= 0 }, () => {
      this.setColumns();
      this.setTableData();
    });
  }

  setColumns = () => {
    const columns = [
      {
        label: (<input type="checkbox" checked={this.state.allChecked} onChange={(e) => {
          // console.log('e---', e.checked);
          this.setState({ allChecked: !this.state.allChecked }, () => {
            this.checkAll();
          })
        }} />),
        field: 'checkship',
        sort: 'disabled',
        // width: 150
      },
      {
        label: 'Order Id',
        field: 'oid',
        sort: 'disabled',
        // width: 150
      },
      {
        label: 'Customer Name',
        field: 'customer',
        sort: 'disabled',
        // width: 150
      },
      {
        label: 'Merchant Name',
        field: 'merchant',
        sort: 'disabled',
        // width: 270
      },
      // {
      //   label: 'Status',
      //   field: 'status',
      //   sort: 'disabled',
      //   // width: 200
      // },
    ];
    this.setState({ columns });
  }

  setTableData = () => {
    const { orders } = this.state;
    const rows = [];
    orders.map((order, index) => { // eslint-disable-line
      rows.push({
        checkship: (<input type="checkbox" checked={order.isChecked} onChange={(e) => {
          this.checkboxCallback(index);
        }} />),
        oid: `#${order.awbnumber}`,
        customer: order.customername,
        merchant: order.storename,
        // pickup_addr: order.pickup_addr,
        // drop_addr: order.drop_addr,
        // slot: order.time_slot,
        status: order.deliverystatus,
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/order-view/${order.shipmentid}`)
            }}>
              <i className="mdi mdi-eye" id="editDriver" title="View order"></i>
            </a>
            <a href="/" className="m-l-10" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/track-shipment/${order.shipmentid}`)
            }}>
              <i className="mdi mdi-clipboard-text" id="trackShipment" title="Track shipment"></i>
            </a>
          </React.Fragment>
        )
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }

  allocateShipment = async (e) => {
    e.preventDefault();
    const { selectedDriver, orders } = this.state;
    const userData = await customFunctions.getUserData();
    if (!userData) return;
    const selectedShipments = orders.filter(order => order.isChecked);
    if (selectedShipments.length <= 0) {
      NotificationManager.error('Please select shipments to allocate');
      return;
    }
    const payload = {
      userid: userData.data.id,
      deliveryagentmasterid: selectedDriver
    };
    console.log('selectedShipments', selectedShipments);
    var successShipmentAllocate = 0;
    for (let i = 0; i < selectedShipments.length; i++) {
      payload.shippingid = selectedShipments[i].shipmentid.toString();
      const { data, message } = await AuthApi.postDataToServer(Api.allocateShipmentUrl, payload);
      if (data && data.status !== "Fail") {
        successShipmentAllocate = successShipmentAllocate + 1;
      }
    }
    if (successShipmentAllocate == selectedShipments.length) {
      NotificationManager.success('shipments allocated');
    } else {
      NotificationManager.error(selectedShipments.length + " out of " + (selectedShipments.length - successShipmentAllocate) + ' shipments allocation failed');
    }
    this.getOrders(1);
  }

  render() {
    const { rows, showDelete, keyword, totalCount, currentPage, limit, drivers, selectedDriver, columns } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.deteleDriver() }}
                  onCancel={() => { this.setState({ showDelete: false, driverId: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.allocateShipment}>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          {/* <input className="form-control" value={keyword} type="text" placeholder="Search here" id="example-text-input" onChange={(e) => { this.setState({ keyword: e.target.value }) }} required={true} /> */}
                          <select className="form-control" placeholder="Select driver" required={true} value={selectedDriver} onChange={(e) => {
                            this.setState({ selectedDriver: e.target.value })
                          }} >
                            <option value="">Select driver</option>
                            {
                              drivers.map(driver => (
                                <option value={driver.driverid}>{`${driver.first_name} ${driver.last_name}`}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Allocate
                        </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getOrders}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </AUX>
    );
  }
}

export default withRouter(AllocateShipment);
