import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';
import orderData from '../../../helper/sampledata/orders';
import SharedService from '../../../helper/SharedService';

const columns = [
  {
    label: 'Order Id',
    field: 'oid',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Customer Name',
    field: 'customer',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Customer Contact',
    field: 'custcontact',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Store Name',
    field: 'merchant',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Merchant Contact',
    field: 'merchantcontact',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Driver',
    field: 'driver',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Driver Contact',
    field: 'drivercontact',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Time slot',
    field: 'timeslot',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled'
  }
];

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      rows: [],
      orderId: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      cityid: '',
      countryid: '',
      countries: [],
      cities: [],
      showCountryCityFilter: true
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('All Orders');
    this.getCountries();
    this.checkAppliedFilter();
    this.subscribed = SharedService.cityChanged.subscribe(this.checkAppliedFilter);
  }

  componentWillUnmount = () => {
    if (this.subscribed) this.subscribed.unsubscribe();
  }

  checkAppliedFilter = async () => {
    const countryid = await localStorage.getItem('admincountry') || '';
    const cityid = await localStorage.getItem('admincity') || '';
    const showCountryCityFilter = cityid ? false : true;
    this.setState({ countryid, cityid, showCountryCityFilter }, () => {
      this.getOrders(1);
    })
  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryid}`);
    if (data && data.status !== "Fail") {
      this.setState({ cities: data.data });
    }
  }


  getOrders = async (currentPage) => {
    const { keyword, limit, cityid, countryid } = this.state;
    const pageNo = currentPage || 1;
    const endPoint = `${Api.ordersUrl}`;
    const payload = {
      skip: pageNo,
      limit
    }
    if (keyword) payload.searchfield = keyword;
    if (cityid) payload.cityid = cityid;
    if (countryid) payload.countryid = countryid;
    const { data, message } = await AuthApi.postDataToServer(endPoint, payload);
    if (data) {
      this.setState({
        orders: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      NotificationManager.error(message);
    }
  }

  setTableData = () => {
    const { orders } = this.state;
    const rows = [];
    orders.map((order) => { // eslint-disable-line
      rows.push({
        oid: `#${order.awbnumber}`,
        customer: order.customername,
        merchant: order.storename,
        merchantcontact: order.vendornumber || '-',
        custcontact: order.shipmobilenumber || '-',
        driver: order.drivername || '-',
        drivercontact: order.drivercontact || '-',
        timeslot: order.timeslot,
        status: order.deliverystatus,
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/order-view/${order.awbnumber}`)
            }}>
              <i className="mdi mdi-eye" id="editDriver" title="View order"></i>
            </a>
            <a href="/" className="ml1" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/track-shipment/${order.awbnumber}`)
            }}>
              <i className="mdi mdi-clipboard-text" id="trackShipment" title="Track shipment"></i>
            </a>
            <a href="/" className="ml1" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.setState({ showDelete: true, orderId: order.awbnumber })
            }}>
              <i className="mdi mdi-delete text-danger" title="Delete order"></i>
            </a>
          </React.Fragment>
        )
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }


  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getOrders(1);
  }

  clearSearch = () => {
    const { showCountryCityFilter, cityid, countryid } = this.state;
    const city = showCountryCityFilter ? '' : cityid;
    const country = showCountryCityFilter ? '' : countryid;
    this.setState({ keyword: '', cityid: city, countryid: country }, () => {
      this.getOrders();
    })
  }

  deteleOrder = async () => {
    const { orderId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(`${Api.deleteOrder}/${orderId}`);
    if (data && data.status !== "Fail") {
      this.setState({ showDelete: false, orderId: null }, () => {
        this.getOrders(1);
      });
      NotificationManager.success('Order deleted');
    } else {
      NotificationManager.error(message);
    }
  }

  render() {
    const { rows, showDelete, keyword, totalCount, currentPage, limit, showCountryCityFilter, cityid, cities, countryid, countries } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.deteleOrder() }}
                  onCancel={() => { this.setState({ showDelete: false, orderId: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.onSearchSubmit}>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <input className="form-control" value={keyword} type="text" placeholder="Search here" id="example-text-input" onChange={(e) => { this.setState({ keyword: e.target.value }) }} />
                        </div>
                        {
                          showCountryCityFilter && (
                            <React.Fragment>

                              <div className="col-sm-3">
                                <select className="form-control" value={countryid} onChange={(e) => {
                                  this.setState({ countryid: e.target.value, cityid: '' });
                                  this.getCities(e.target.value);
                                }}>
                                  <option value=''>Select country</option>
                                  {
                                    countries.map((country) => (
                                      <option value={country.countryid}>{country.countryname_eng}</option>
                                    ))
                                  }
                                </select>
                              </div>
                              <div className="col-sm-3">
                                <select className="form-control" value={cityid} onChange={(e) => {
                                  this.setState({ cityid: e.target.value });
                                  // this.getCities(e.target.value);
                                }}>
                                  <option value=''>Select city</option>
                                  {
                                    cities.map((city) => (
                                      <option value={city.cityid}>{city.cityname_eng}</option>
                                    ))
                                  }
                                </select>
                              </div>
                            </React.Fragment>
                          )
                        }
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Search
                        </button>
                          <button type="reset" onClick={() => { this.clearSearch(); }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      responsive
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getOrders}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </AUX>
    );
  }
}

export default withRouter(Orders);   