import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import MaterialInput from '@material-ui/core/Input';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import Geocode from "react-geocode";
import constants from '../../../helper/constants';
import './style.css';
import PlaceSearch from '../../../components/PlaceSearch';

Geocode.setApiKey(constants.mapKey);

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      mail: '',
      storeName: '',
      password: '',
      mobile: '',
      countrycode: '',
      countryid: '',
      cityid: '',
      confirmPassword: '',
      fnameErr: '',
      lnameErr: '',
      mailErr: '',
      mobileErr: '',
      countrycodeErr: '',
      countryErr: '',
      cityErr: '',
      passErr: '',
      cpassErr: '',
      storeNameErr: '',
      roleid: 2,
      userId: null,
      countries: [],
      cities: [],
      addr1: '',
      formattedAddress: '',
      geoLocErr: '',
      geoLocation: {},
      placeInText: '',
      setPickupBound: {},
      skippayment: 0,
      amountErr: '',
      amount: '',
      vendorid: null
    }
  }

  componentDidMount = async () => {
    const userId = this.props.match.params.userId;
    this.setState({ userId });
    this.getCountries();
    if (userId) {
      this.getMerchantData(userId);
      customFunctions.setTitle('Edit User');
    } else {
      customFunctions.setTitle('Add User');
    }
  }

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== "Fail") {
      this.setState({ countries: data.data });
    }
  }

  getCities = async (countryid) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getCities}/${countryid}`);
    if (data && data.status !== "Fail") {
      this.setState({ cities: data.data });
    }
  }

  getMerchantData = async (userId) => {
    const { data, message } = await AuthApi.getDataFromServer(`${Api.getUserStore}?userid=${userId}`);
    if (data && data.data) {
      this.setState({
        firstname: data.data.firstname,
        lastname: data.data.lastname,
        mail: data.data.mail,
        mobile: data.data.mobile,
        countrycode: data.data.countrycode ? data.data.countrycode.toString() : '',
        vendorid: data.data.vendorid
      })
    }
    else {
      var $this = this;
      NotificationManager.error(message, '', 1000);
      setTimeout(() => {
        $this.props.history.push('/merchants');
      }, 1000);
    }
  }

  validateForm = (e) => {
    e.preventDefault();
    const { firstname, lastname, mail, mobile, countryid, cityid, countrycode, password, confirmPassword, userId, storeName, geoLocation, amount } = this.state;
    let fnameErr = '', lnameErr = '', mailErr = '', mobileErr = '', passErr = '', cpassErr = '', storeNameErr = '', countryErr = '', cityErr = '', countrycodeErr = '', geoLocErr = '', amountErr = '';
    let isValid = true;
    if (firstname.trim() === '') {
      fnameErr = 'First name is required'; isValid = false
    }

    if (lastname.trim() === '') {
      lnameErr = 'Last name is required'; isValid = false
    }

    if (mail.trim() === '') {
      mailErr = 'Email is required'; isValid = false;
    } else if (!customFunctions.validateEmail(mail)) {
      mailErr = 'Invalid email'
    }

    if (countrycode.trim() === '') {
      countrycodeErr = 'Country code is required'; isValid = false
    }

    if (String(mobile).trim() === '') {
      mobileErr = 'Mobile is required'; isValid = false;
    } else if (!customFunctions.validatePhoneNumber(String("+" + countrycode + " " + mobile))) {
      mobileErr = 'Invalid mobile'; isValid = false;
    }

    if ((!userId) || (userId && (password.trim() !== '' || confirmPassword.trim() !== ''))) {
      if (password.trim() === '') {
        passErr = 'Password is required'; isValid = false;
      }
      if (password !== '' && confirmPassword.trim() === '') {
        cpassErr = 'Password confirmation is required'; isValid = false;
      } else if (password !== confirmPassword) {
        cpassErr = 'Password did not match'; isValid = false;
      }
    }

    this.setState({ fnameErr, lnameErr, mailErr, mobileErr, passErr, cpassErr, storeNameErr, countryErr, cityErr, countrycodeErr, geoLocErr, amountErr });
    if (isValid) {
      this.addMerchant()
    }
  }

  addMerchant = async () => {
    const { firstname, lastname, mail, mobile, vendorid, password, confirmPassword, roleid, userId, storeName, countryid, cityid, addr1, geoLocation, countrycode, pickuplocationmasterid, amount, skippayment } = this.state;

    const postData = {
      firstname,
      lastname,
      mail,
      mobile: String(mobile),
      countrycode: parseInt(countrycode),
      password: confirmPassword,
      roleid: parseInt(roleid),
      countrycode: countrycode,
      vendorid: vendorid,
      userid: userId
    };
    const callback = userId ? AuthApi.putDataToServer : AuthApi.postDataToServer;
    const endPoint = userId ? Api.editVendorForStore : Api.addVendor;
    // if (userId) {
    //   postData.vendorid = parseInt(merchantId);
    //   postData.pickuplocationmasterid = pickuplocationmasterid;
    // } else {
    //   postData.sendemail = 0;
    //   postData.mail = mail;
    //   postData.password = password;
    //   postData.roleid = roleid;
    // }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.status !== "Fail") {
      if (userId) {
        var $this = this;
        NotificationManager.success('User edited', '', 900);
        setTimeout(() => {
          $this.props.history.push(`/merchant-users/${vendorid}`);
        }, 1000);
        return;
      }
      NotificationManager.success('User added');
      this.resetData();
    } else {
      NotificationManager.error((data && data.message) || message || 'Please try again later');
    }
  }

  resetData = () => {
    this.setState({
      firstname: '', lastname: '', mail: '', password: '', mobile: '', countryid: '', cityid: '', countrycode: '', confirmPassword: '', fnameErr: '', lnameErr: '', mailErr: '', mobileErr: '', passErr: '', cpassErr: '', roleid: 2, storeNameErr: '', storeName: '', countryErr: '', cityErr: '', countrycodeErr: '',
      geoLocation: {}, formattedAddress: '', placeInText: '', addr1: '', skippayment: 0, amount: ''
    });
  }

  render() {
    const {
      firstname, lastname, mail, mobile, password, countrycode, confirmPassword, fnameErr, lnameErr, mobileErr, mailErr, passErr, cpassErr, merchantId, storeNameErr, storeName, countryErr, cityErr, countrycodeErr, countries, cities, addr1, setPickupBound, geoLocation, formattedAddress, placeInText, geoLocErr, skippayment, amount, amountErr
    } = this.state;
    const { google } = this.props;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">First Name*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={firstname} type="text" placeholder="John" id="example-text-input" onChange={(e) => { this.setState({ firstname: e.target.value }) }} />
                          <span id="err">{fnameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-search-input" className="col-sm-2 col-form-label">Last Name*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={lastname} type="text" placeholder="Doe" id="example-search-input" onChange={(e) => { this.setState({ lastname: e.target.value }) }} />
                          <span id="err">{lnameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-email-input" className="col-sm-2 col-form-label">Email*</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={mail} type="text" placeholder="user@example.com" id="example-email-input" onChange={(e) => { this.setState({ mail: e.target.value }) }} disabled={merchantId} />
                          <span id="err">{mailErr}</span>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label for="example-text-input" className="col-sm-2 col-form-label">Country Code*</label>
                        <div className="col-sm-5">
                          <select className="form-control" value={countrycode} onChange={(e) => {
                            this.setState({ countrycode: e.target.value });
                          }}>
                            <option value=''>Select country code</option>
                            {
                              countries.map((country) => (
                                <option value={country.countrycode}>+{country.countrycode}</option>
                              ))
                            }
                          </select>

                          <span id="err">{countrycodeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Mobile*</label>
                        <div className="col-sm-5">

                          <input type="text" className="form-control" value={mobile} placeholder="9999999999"
                            onChange={(e) => {
                              var val = e.target.value;
                              var phoneformat = /^\d{1,15}$/;
                              if (val === '' || val.match(phoneformat)) {
                                this.setState({ mobile: val })
                              }
                            }}
                          />
                          <span id="err">{mobileErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-url-input" className="col-sm-2 col-form-label">Password
                        {!merchantId && (<React.Fragment>*</React.Fragment>)}</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={password} type="password" placeholder="*****" id="example-url-input" onChange={(e) => { this.setState({ password: e.target.value }) }} />
                          <span id="err">{passErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label for="example-tel-input" className="col-sm-2 col-form-label">Confirm Password
                        {!merchantId && (<React.Fragment>*</React.Fragment>)}</label>
                        <div className="col-sm-5">
                          <input className="form-control" value={confirmPassword} type="password" placeholder="*****" id="example-tel-input" onChange={(e) => { this.setState({ confirmPassword: e.target.value }) }} />
                          <span id="err">{cpassErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button type="submit" className="btn btn-primary waves-effect waves-light">
                            Submit
                        </button>
                          <button type="reset" onClick={() => {
                            this.props.history.push('/merchants');
                          }} className="btn btn-secondary waves-effect m-l-10">
                            Cancel
                        </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

// export default withRouter(AddMerchant);

export default GoogleApiWrapper({
  // apiKey: 'AIzaSyD46yuo9rWxFv9luFDL8ua6OMdiymeL0fY'
  apiKey: constants.mapKey
})(EditUser);
