import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';
import BankDetails from '../../../components/BankDetails';
import SweetAlert from 'react-bootstrap-sweetalert';

const columns = [
  {
    label: 'Name',
    field: 'name',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Email',
    field: 'email',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Mobile',
    field: 'mobile',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled'
  }
];

class MerchantUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchants: [],
      rows: [],
      merchantId: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      countryid: '',
      cityid: '',
      countries: [],
      cities: [],
      showCountryCityFilter: true,
      bankDetails: {},
      hasDetails: true,
      addressList: [],
      userId: null
    }
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Users');
    const vendorId = this.props.match.params.vendorId;
    if (!vendorId) {
      this.props.history.push('/dashboard');
      return;
    }
    this.getMerchantAddresses(vendorId);
    this.setState({ vendorId }, () => {
      this.getMerchants();
    });
  }

  getMerchants = async (currentPage) => {
    const { vendorId } = this.state;
    const pageNo = currentPage || 1;
    const endPoint = `${Api.getStoreUsers}${vendorId}`;
    const callback = AuthApi.getDataFromServer;

    const { data, message, status } = await callback(endPoint);
    if (data) {
      this.setState({
        merchants: data.data,
        totalCount: data.totalcount,
        currentPage: pageNo
      }, () => {
        this.setTableData();
      });
    } else {
      if (status === 401) {
        this.props.history.push('/login');
        return;
      }
      NotificationManager.error(message);
    }
  }

  setTableData = () => {
    const { merchants } = this.state;
    console.log("merchants", merchants);
    const rows = [];
    merchants.map((merchant, i) => { // eslint-disable-line
      rows.push({
        name: `${merchant.first_name} ${merchant.last_name}`,
        email: `${merchant.email}`,
        mobile: `${merchant.mobile}`,
        actions: (
          <React.Fragment>
            <a href="/" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.props.history.push(`/edit-users/${merchant.userid}`)
            }}>
              <i className="mdi mdi-lead-pencil" id="editDriver" title="Edit User"></i>
            </a>
            <a href="/" className="m-l-10" onClick={(e) => {
              e.preventDefault(); e.stopPropagation();
              this.setState({ showDelete: true, userId: merchant.userid })
            }}>
              <i className="mdi mdi-delete text-danger" title="Delete User"></i>
            </a>
          </React.Fragment>
        )
      })
    })
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  }

  deteleUser = async () => {
    const { userId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(`${Api.deleteVendorForStore}?userid=${userId}`);
    if (data) {
      this.setState({ showDelete: false, userId: null }, () => {
        this.getMerchants();
      });
      NotificationManager.success('Merchant deleted');
    } else {
      NotificationManager.error(message);
    }
  }

  getMerchantAddresses = async (vendorId) => {
    const { data } = await AuthApi.getDataFromServer(`${Api.getVendorAddress}/${vendorId}`);
    if (data && data.status !== "Fail") {
      this.setState({ addressList: data.data });
    }
  }

  render() {
    const { rows, showDelete, limit, totalCount, currentPage, bankDetails, hasDetails, addressList } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {
              showDelete && (
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => { this.deteleUser() }}
                  onCancel={() => { this.setState({ showDelete: false, merchantId: null }) }} >
                  You won't be able to revert this!
                </SweetAlert>
              )
            }
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="mb-5">
                      {
                        addressList.length > 0 && (
                          <>
                            <h5>
                              Store name: {addressList.length > 0 ? addressList[0].pickuplocationname : '-'}
                            </h5>
                            <label>Address:</label> {addressList.length > 0 ? addressList[0].pickuplocationaddress1 : '-'}
                          </>
                        )
                      }
                    </div>

                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      responsive
                      data={{ columns, rows }}
                      searching={false}
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getMerchants}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BankDetails
          bankDetails={bankDetails}
          hasDetails={hasDetails}
        />
      </AUX>
    );
  }
}

export default withRouter(MerchantUsers);
